import axios from 'axios';
import React, { useEffect, useState } from 'react'

const AreaContainerEditModal = ({ closeModal, container }) => {
    const [name, setName] = useState(container.name);
    const [detial, setDetial] = useState(container.address);
    const [owner, setOwner] = useState(container.owner);
    const [ownerphone, setOwnerphone] = useState(container.ownerPhone);
    const [user, setUser] = useState(container.user);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://api.turbodelivery.mn/user?role=operator')
                .then((e) => {
                    setUsers(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e))
        }
    }, [isLoading])

    const addCategory = () => {
        axios.put('https://api.turbodelivery.mn/areacontainer/' + container._id, {
            name: name,
            user: user,
            owner: owner,
            address: detial,
            ownerPhone: ownerphone
        })
            .then(() => window.location.reload(false))
            .catch((e) => e)
    }
    return (
        <div className='blur_container'>
            <div className='modal'>
                <div className='two-line'>
                    <h2>Агуулах мэдээлэл засах</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label>
                    Агуулах нэр:
                    <input type="text" onChange={(e) => setName(e.target.value)} value={name} />
                </label>
                <label>
                    Дэлгэрэнгүй тайлбар:
                    <input type="text" onChange={(e) => setDetial(e.target.value)} value={detial} />
                </label>
                <label>
                    Нярав сонгох:
                    <select name="" id="" onChange={(e) => setUser(e.target.value)}>
                        {
                            users.filter((e) => e._id === container.user).length > 0
                                ? <option value={container.user}>
                                    {users
                                        .filter((e) => e._id === container.user).map((e) => (
                                            e.name
                                        ))
                                    }
                                </option> :
                                <option value="">Сонгох</option>
                        }
                        {users.filter((e) => e._id !== container.user).map((e) => (
                            <option value={e._id}>{e.name}</option>
                        ))}
                    </select>
                </label>
                <label>
                    Агуулах эзэмшигчийн нэр:
                    <input type="text" onChange={(e) => setOwner(e.target.value)} value={owner} />
                </label>
                <label>
                    Агуулах эзэмшигчийн дугаар:
                    <input type="text" onChange={(e) => setOwnerphone(e.target.value)} value={ownerphone} />
                </label>
                <div className='button_container'>
                    <button onClick={addCategory} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default AreaContainerEditModal
