import React, { useEffect, useState } from 'react'
import styles from './Order.module.css'
// import OrderTable from '../../Utills/Table/OrderTable';
import Navbar from '../../Components/Navbar/Navbar';
import OrderAddModal from '../../Utills/Modal/OrderAddModal';
import axios from 'axios';
import EditOrderModal from '../../Utills/Modal/EditModal/EditOrderModal';
import ExportExcel from '../../excelexport';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import OrderItemListTable from '../../Utills/Table/OrderItemListTable';

const OrderItems = ({ ordersCatch }) => {
    const [isModal, setIsModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editId, setEditId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState(ordersCatch);
    const [drivers, setDriver] = useState([]);
    const [users, setUsers] = useState([]);
    const [shops, setShops] = useState([]);
    const [user, setUser] = useState('');
    const [status, setStatus] = useState('');
    const [excelData, setExcelData] = useState([{ утас: '', хаяг: '', төлөв: '', 'нийт дүн': '', огноо: '', жолооч: '' }])
    const [isEndCalendar, setIsEndCalendar] = useState(false);
    const [endValue, setEndValue] = useState(dayjs());
    const [phone, setPhone] = useState('');
    const [itemName, setItemName] = useState('');
    const [isSearch, setSearch] = useState(false);
    const [isStartCalendar, setIsStartCalendar] = useState(false);
    const [startValue, setStartValue] = useState(dayjs());
    const [isCreated, setIsCreated] = useState(false);

    const closeModal = () => {
        setIsModal(false);
        setEditModal(false);
    }

    useEffect(() => {
        window.localStorage.setItem('side-id', 1);
        if (isLoading) {
            const fetchDrivers = axios.get('https://api.turbodelivery.mn/user?sort=-createdAt&role=driver');
            const fetchUsers = axios.get('https://api.turbodelivery.mn/user?role=user');
            // const fetchOrders = axios.get('https://api.turbodelivery.mn/order?sort=-createdAt');

            Promise.all([fetchDrivers, fetchUsers,])
                .then((responses) => {
                    // alert(
                    //     responses[0].data.data.length
                    // )
                    setDriver(responses[0].data.data);
                    setUsers(responses[1].data.data);
                    // setOrders(responses[3].data.data);
                })
                .catch((e) => console.log(e))
                .finally(() => setIsLoading(false));
        }
        // if (isLoading) {
        //     axios.get('https://api.turbodelivery.mn/user?sort=-createdAt&role=driver')
        //         .then((e) => {
        //             setDriver(e.data.data);
        //         })
        //         .catch((e) => console.log(e));

        //     // axios.get('https://api.turbodelivery.mn/order?sort=-createdAt')
        //     //     .then((order) => {
        //     //         setOrders(order.data.data);
        //     //         setIsLoading(false);
        //     //     })
        //     //     .catch((e) => console.log(e));

        //     axios.get('https://api.turbodelivery.mn/user?role=user')
        //         .then((e) => {
        //             setUsers(e.data.data);
        //             setIsLoading(false);
        //         });

        //     axios.get('https://api.turbodelivery.mn/shop')
        //         .then((e) => {
        //             setShops(e.data.data.filter((e) => e.visible !== 'true'));
        //             setIsLoading(false);
        //         });
        // }

        if (!isLoading) {
            const exceldatas = orders
                .filter((e) => user ? e.user === user : e)
                .filter((e) => isSearch ?
                    (
                        (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(endValue, 'date') ||
                            dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isBefore(endValue))
                        &&
                        (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(startValue, 'date') ||
                            dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isAfter(startValue))
                    )
                    : e)
                .flatMap((order) => {
                    return order.order.map(item => ({
                        утас: parseInt(order.phone),
                        хаяг: order.address,
                        дэлгүүр: users.find((e) => e._id === order.user)?.shop,
                        жолооч: drivers.find((e) => e._id === order.driver)?.name,
                        төлөв: order.status,
                        'нийт дүн': order.price,
                        'Үүсгэсэн огноо': dayjs(order.createdAt).format('YYYY-MM-DD'),
                        'Төлөв өөрчилсөн огноо': dayjs(order.orderDate).subtract(8, 'hour').format('YYYY-MM-DD'),
                        'бараа нэр': item.name,
                        'бараа дүн': item.price,
                        'бараа тоо': item.unit,
                        'хөнгөлөлт': parseInt(order.discount)
                    })
                    )
                }
                )
            setExcelData(exceldatas);
        }


    }, [isLoading, drivers, orders, isSearch, isCreated, startValue, endValue, shops, user])

    const edit = ({ id }) => {
        setEditId(id);
        setEditModal(true);
    }

    return (
        <>
            {!isModal ? <Navbar title={'Захиалгын бараанууд'} /> : <></>}
            {isModal ? <div className='abs'><OrderAddModal closeModal={closeModal} /></div> : <></>}
            {editModal ? <div className='abs'><EditOrderModal id={editId} closeModal={closeModal} /></div> : <></>}
            {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
            <div className={styles.container}>
                <div className='line-start'>
                    <label>
                        Төлвөөр шүүх:
                        <select name="" id="" onChange={(e) => setStatus(e.target.value)}>
                            <option value=''>Бүгд</option>
                            <option value='Хүлээгдэж байна'>Хүлээгдэж байна</option>
                            <option value='Буцаагдсан'>Буцаагдсан</option>
                            <option value='Жолооч авсан'>Жолооч авсан</option>
                            <option value='Маргааш авах'>Маргааш авах</option>
                            <option value='Хойшлуулсан'>Хойшлуулсан</option>
                            <option value='Хүргэсэн'>Хүргэсэн</option>
                            <option value='Хаягаар очсон'>Хаягаар очсон</option>
                            <option value='Хэсэгчлэн хүргэсэн'>Хэсэгчлэн хүргэсэн</option>
                        </select>
                    </label>
                    <ExportExcel fileName={'name'} excelData={excelData} />
                    {/* <ExportExcel fileName={'name'} excelData={
                        orders
                    } haveOrders={true} drivers={drivers} shops={shops} /> */}
                    <label>
                        Дэлгүүрээр шүүх:
                        <select name="" id="" onChange={(e) => setUser(e.target.value)}>
                            <option value=''>Бүгд</option>
                            {users.map((e) => (
                                <option value={e._id}>{e.shop}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <br />
                <div className="line-start">
                    {isStartCalendar ? (
                        <div className="calendar-div" style={{ zIndex: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                    <DemoItem>
                                        <p onClick={() => setIsStartCalendar(false)}>Hide</p>
                                        <DateCalendar value={startValue}
                                            onChange={(newValue) => { setStartValue(newValue); setIsStartCalendar(false) }} />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    ) : (
                        <label className='calendar-label' onClick={() => setIsStartCalendar(true)}>
                            {/* End date: */}
                            Эхлэх хугацаа:
                            <p className='calendar'>{startValue.format('YYYY-MM-DD')}</p>
                        </label>
                    )}
                    {isEndCalendar ? (
                        <div className="calendar-div" style={{ zIndex: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                    <DemoItem>
                                        <p onClick={() => setIsEndCalendar(false)}>Hide</p>
                                        <DateCalendar value={endValue}
                                            onChange={(newValue) => { setEndValue(newValue); setIsEndCalendar(false) }} />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    ) : (
                        <label className='calendar-label' onClick={() => setIsEndCalendar(true)}>
                            {/* End date: */}
                            Дуусах хугацаа:
                            <p className='calendar'>{endValue.format('YYYY-MM-DD')}</p>
                        </label>
                    )}

                    {isSearch ? <button style={{ fontSize: '14px', padding: '5px 7px' }} className='button-white'
                        onClick={() => setSearch(false)}
                    >
                        Бүгдийг харах
                    </button> :
                        <button style={{ fontSize: '14px', padding: '5px 25px' }} className='button'
                            onClick={() => setSearch(true)}
                        >
                            Хайх
                        </button>}
                    {isCreated && isSearch ? <button style={{ fontSize: '14px', padding: '5px 7px' }} className='button-white'
                        onClick={() => setIsCreated(false)}
                    >
                        Үйлдэл  дарсан огноо
                    </button> : isSearch &&
                    <button style={{ fontSize: '14px', padding: '5px 25px' }} className='button'
                        onClick={() => setIsCreated(true)}
                    >
                        Үүсгэсэн огноо
                    </button>}
                </div>
                <br />
                <div>
                    {/* <OrderItemTable orders={orders}/> */}
                    <OrderItemListTable orders={orders
                        .filter((e) => phone ? e.phone.includes(phone) : e)
                        .filter((e) => isSearch ?
                            (
                                (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(endValue, 'date') ||
                                    dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isBefore(endValue))
                                &&
                                (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(startValue, 'date') ||
                                    dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isAfter(startValue))
                            )
                            : e)
                        .filter((e) => status ? e.status === status : e)
                    }
                        itemname={itemName}
                        drivers={drivers} edit={edit} user={users} filteruser={user} />
                </div>
            </div>
        </>
    )
}

export default OrderItems
