import React, { useEffect, useState } from 'react'
import styles from './Container.module.css'
import ContainerTable from '../../Utills/Table/ContainerTable'
import Navbar from '../../Components/Navbar/Navbar'
import ContainerAddModal from '../../Utills/Modal/ContainerAddModal'
import axios from 'axios'
import MinusContainerModal from '../../Utills/Modal/MinusModal/MinusContainerModal'
import add_icon from '../../Assets/uttils/add_button.svg'
import { Link } from 'react-router-dom'

const Container = () => {
  const [isModal, setIsModal] = useState(false);
  const [minusModal, setMinusModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [containers, setContainers] = useState([]);
  const [users, setUsers] = useState([]);
  const [shops, setShops] = useState([]);
  const [user, setUser] = useState('');
  const [status, setStatus] = useState('');
  const [areacontainers, setAreaContainers] = useState([]);

  const closeModal = () => {
    setIsModal(false);
    setMinusModal(false);
  }

  useEffect(() => {
    window.localStorage.setItem('side-id', 3);
    if (isLoading) {
      // isSaved = false &
      // &isSaved=false
      axios.get('https://api.turbodelivery.mn/container?sort=-createdAt')
        .then((e) => {
          setIsLoading(false);
          setContainers(e.data.data);
        })
        .catch((e) => alert(e));


      axios.get('https://api.turbodelivery.mn/areacontainer')
        .then((e) => {
          setAreaContainers(e.data.data);
        })
        .catch((e) => alert(e));

      axios.get('https://api.turbodelivery.mn/user')
        .then((e) => {
          setIsLoading(false);
          setUsers(e.data.data);
        })
        .catch((e) => alert(e));
    }
  }, [isLoading]);

  return (
    <>
      {!isModal ? <Navbar title={'Агуулах бараа'} /> : <></>}
      {isModal ? <div className='abs'><ContainerAddModal closeModal={closeModal} /></div> : <></>}
      {minusModal ? <div className='abs'><MinusContainerModal closeModal={closeModal} /></div> : <></>}
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <div className={styles.container}>
        <div className='line-start'>
          <Link className='button' style={{ textDecoration: 'none' }} to={'/containerpackage'}>Агуулах багцаар үзэх</Link>
          <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
          <button onClick={() => setMinusModal(true)} className='button'>Бараа хасах</button>
          <label htmlFor="">
            Дэлгүүрээр шүүх
            <select name="" id="" onChange={(e) => setUser(e.target.value)}>
              <option value="">Сонгох</option>
              {users.filter((e) => e.shop).map((e) => (
                <option value={e._id}>{e.shop}</option>
              ))}
            </select>
          </label>
          <label htmlFor="">
            Статусаар шүүх:
            <select name="" id="" onChange={(e) => setStatus(e.target.value)}>
              <option value="">Сонгох</option>
              <option value="Хүлээж авсан">Хүлээж авсан</option>
              <option value="Хүлээгдэж байна">Хүлээгдэж байна</option>
              <option value="Цуцалсан">Цуцалсан</option>
              <option value="Зарлага">Зарлага</option>
            </select>
          </label>
        </div>
        <ContainerTable areacontainers={areacontainers} containers={containers}
          filterStatus={status} user={user} users={users} />
      </div>
    </>
  )
}

export default Container
