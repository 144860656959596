import React, { useEffect, useState } from 'react'
import styles from './State.module.css'
import Navbar from '../../Components/Navbar/Navbar'
import axios from 'axios'
import add_icon from '../../Assets/uttils/add_button.svg'
import StateTable from '../../Utills/Table/StateTable'
import AddStateModal from '../../Utills/Modal/AddState'

const State = () => {
    const [isModal, setIsModal] = useState(false);
    const [states, setStates] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

    useEffect(() => {
        if (isLoad) {
            window.localStorage.setItem('side-id', 11);
            axios.get('https://api.turbodelivery.mn/state')
                .then((e) => {
                    setStates(e.data.data);
                    setIsLoad(false);
                })
                .catch((e) => console.log(e));
        }
    }, [isLoad])

    const closeModal = () => {
        setIsModal(false);
    }
    return (
        <>
            {!isModal ? <Navbar title={'Бүс'} /> : <></>}
            {isModal ? <AddStateModal closeModal={closeModal} /> : <></>}
            {isLoad ? <div className='loader-container'><div className="loader"></div></div> : <></>}
            <div className={styles.container}>
                <div className="space-between" style={{ justifyContent: 'space-between', paddingRight: '2vw' }}>
                    <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
                </div>
                <StateTable states={states} />
            </div>
        </>
    )
}

export default State
