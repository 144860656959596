import React, { useEffect, useState } from 'react'
import styles from './Users.module.css'
import Navbar from '../../Components/Navbar/Navbar'
import axios from 'axios';
import UserAddModal from '../../Utills/Modal/UserAddModal';
import UsersTable from '../../Utills/Table/UserTable';

const Users = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isModal, setIsModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [role, setRole] = useState('');
    const [search, setSeach] = useState('');

    useEffect(() => {
        if (isLoading) {
            window.localStorage.setItem('side-id', 8);
            axios.get('https://api.turbodelivery.mn/user?sort=-createdAt&role=user')
                .then((e) => {
                    setUsers(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e));
        }
    }, [isLoading])

    const closeModal = () => {
        setIsModal(false);
    }

    return (
        <>
            {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
            {isModal ? <UserAddModal isUser={true} closeModal={closeModal} /> : <></>}
            <Navbar title={'Хянах самбар'} />
            <div className={styles.user_container}>
                <div className="line-three">
                    <button onClick={(e) => setIsModal(true)} className='button'>Хэрэглэгч нэмэх</button>
                    <label>
                        Search:
                        <input className='search' value={search} onChange={(e) => setSeach(e.target.value)} type="text" name="" id="" />
                    </label>
                    <label>
                        Role filter:
                        <select onChange={(e) => setRole(e.target.value)} name="" id="">
                            <option value=""></option>
                            <option value="driver">Жолооч</option>
                            <option value="user">Харилцагч </option>
                        </select>
                    </label>
                </div>
                <UsersTable users={users} roleFilter={role} search={search} />
            </div>
        </>
    )
}

export default Users
