import axios from 'axios';
import React, { useState } from 'react'

const ContainerAllStatusChangeModal = ({ closeModal, id, reportId, refresh }) => {
    const [status, setStatus] = useState('');

    const changeStatus = () => {
        // alert(id);
        axios.put('https://api.turbodelivery.mn/container/all/' + encodeURIComponent(id), {
            status: status
        })
            .then(() => window.location.reload(false))
            .catch((e) => console.log(e));
    }
    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Агуулхын барааны төлөв багцаар солих</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label htmlFor="">
                    Төлөв солих:
                    <select onChange={(e) => setStatus(e.target.value)}>
                        {/* enum: ['Хүлээгдэж байна', 'Хүлээж авсан', 'Цуцалсан', 'Зарлага'], */}
                        <option value="">Choose</option>
                        <option value="Хүлээж авсан">Хүлээж авсан</option>
                        <option value="Цуцалсан">Цуцалсан</option>
                    </select>
                </label>
                <div className='button_container'>
                    <button onClick={changeStatus} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default ContainerAllStatusChangeModal
