import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import styles from './Finance.module.css';
import Navbar from '../../Components/Navbar/Navbar';
import axios from 'axios';

export default function Finance({ ordersCatch }) {
  const [startValue, setStartValue] = useState(dayjs());
  const [endValue, setEndValue] = useState(dayjs());
  const [isStartCalendar, setIsStartCalendar] = useState(false);
  const [isEndCalendar, setIsEndCalendar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [finance, setFinance] = useState(ordersCatch);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');

  useEffect(() => {
    if (isLoading) {
      window.localStorage.setItem('side-id', 2);
      // axios.get('https://api.turbodelivery.mn/order')
      //   .then((e) => { setFinance(e.data.data); setIsLoading(false) })
      //   .catch((e) => alert(e));

      axios.get('https://api.turbodelivery.mn/user?role=user')
        .then((e) => {
          setUsers(e.data.data);
          setIsLoading(false);
        })
    };
  })

  const calculateTotalPrice = () => {
    return finance
      .filter((e) => user ? (e.user && e.user === user) : e)
      .filter((el) => el.status === 'Хүргэсэн' || el.status === 'Хэсэгчлэн хүргэсэн')
      // .filter((el) => el.unprice === false)
      .reduce(
        (itemTotal, item) =>
          itemTotal +
          ((
            ((dayjs(item.orderDate).subtract(8, 'hour').isBefore(startValue) ||
              dayjs(item.orderDate).subtract(8, 'hour').isSame(startValue, 'date'))
              &&
              dayjs(item.orderDate).subtract(8, 'hour').isAfter(dayjs(endValue))
            )
            || (dayjs(item.orderDate).subtract(8, 'hour').isSame(endValue, 'date')
              && dayjs(item.orderDate).subtract(8, 'hour').isSame(startValue, 'date'))
          ) ? item.price : 0),
        0
      );
  };

  // || (dayjs(item.orderDate).isSame(endValue, 'date') && dayjs(item.orderDate).isSame(startValue, 'date'))

  const calculateTotalDeposit = () => {
    return finance
      .filter((e) => user ? (e.user && e.user === user) : e)
      // .filter((el) => el.status === true)
      // .filter((el) => el.status === 'Хүргэсэн' || el.status === 'Хэсэгчлэн хүргэсэн')
      .reduce(
        (itemTotal, item) =>
          itemTotal +
          (
            (dayjs(dayjs(item.orderDate).subtract(8, 'hour')).isBefore(dayjs(startValue))
              && dayjs(dayjs(item.orderDate).subtract(8, 'hour')).isAfter(dayjs(endValue)))
              || (dayjs(dayjs(item.orderDate).subtract(8, 'hour')).isSame(endValue, 'date')
                && dayjs(dayjs(item.orderDate).subtract(8, 'hour')).isSame(startValue, 'date'))
              ? item.deposit : 0),
        0
      );
  };

  const calculateTotalDelivered = () => {
    // finance.forEach((e) => {
    //   console.log(e);
    //   console.log(startValue.format('YYYY-MM-DD-HH-mm'));
    // })
    return finance
      .filter((e) => user ? (e.user && e.user === user) : e)
      .filter((el) => el.status === 'Хүргэсэн' || el.status === 'Хэсэгчлэн хүргэсэн' || el.status === 'Хаягаар очсон')
      .filter((el) => (
        ((dayjs(el.orderDate).subtract(8, 'hour').isBefore(startValue)
          ||
          dayjs(el.orderDate).subtract(8, 'hour').isSame(startValue, 'date'))
          && dayjs(el.orderDate).subtract(8, 'hour').isAfter(endValue))
        || (dayjs(dayjs(el.orderDate).subtract(8, 'hour')).isSame(endValue, 'date')
          && dayjs(dayjs(el.orderDate).subtract(8, 'hour')).isSame(startValue, 'date'))
      )
      ).length * 6000;
  };

  return (
    <>
      <Navbar title={'Тооцоо'} />
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <div className={styles.container}>
        <div className='line-start'>
          <div style={{ width: '17vw' }}>
            {isEndCalendar ? (
              <div className="calendar-div">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                    <DemoItem>
                      <p onClick={() => setIsEndCalendar(false)}>Hide</p>
                      <DateCalendar value={endValue}
                        onChange={(newValue) => { setEndValue(newValue); setIsEndCalendar(false) }} />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            ) : (
              <label className='calendar-label' onClick={() => setIsEndCalendar(true)}>
                {/* End date: */}
                Эхлэх хугацаа:
                <p className='calendar'>{endValue.format('YYYY-MM-DD')}</p>
              </label>
            )}
          </div>
          <div style={{ width: '17vw' }}>
            {isStartCalendar ? (
              <div className="calendar-div">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                    <DemoItem>
                      <p onClick={() => setIsStartCalendar(false)}>Hide</p>
                      <DateCalendar value={startValue}
                        onChange={(newValue) => {
                          setStartValue(newValue);
                          setIsStartCalendar(false)
                        }} />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            ) : (
              <label className='calendar-label' onClick={() => setIsStartCalendar(true)}>
                Дуусах хугацаа:
                <p className='calendar'>{startValue.format('YYYY-MM-DD')}</p>
              </label>
            )}
          </div>

          <label>
            Дэлгүүрээр шүүх:
            <select name="" id="" onChange={(e) => setUser(e.target.value)}>
              <option value=''>Бүгд</option>
              {users.map((e) => (
                <option value={e._id}>{e.shop}</option>
              ))}
            </select>
          </label>
        </div>
        <br />
        {/* {startValue.format('YYYY-MM-DD-HH-mm')} */}
        <div className='line-three'>
          <div className='dashboard-card'>
            <p style={{ padding: '10px' }}>Нийт дүн</p>
            <div className='divider-dashboard'></div>
            <h3>{new Intl.NumberFormat('en-Us').format(calculateTotalPrice())}</h3>
          </div>
          <div className='dashboard-card'>
            <p style={{ padding: '10px' }}>Хүргэлтийн дүн</p>
            <div className='divider-dashboard'></div>
            <h3>{new Intl.NumberFormat('en-Us').format(calculateTotalDelivered())}</h3>
          </div>
          <div className='dashboard-card'>
            <p style={{ padding: '10px' }}>Татан авалтын дүн</p>
            <div className='divider-dashboard'></div>
            {/* <h3>{new Intl.NumberFormat('us-En').format(calculateTotalDeposit())}</h3> */}
            <h3>0</h3>
          </div>
        </div>
      </div>
    </>
  );
}
