import React, { useEffect, useState } from 'react'
import styles from './AddContainer.module.css'
import Navbar from '../../Components/Navbar/Navbar'
import axios from 'axios';
import AreaContainerTable from '../../Utills/Table/AreaContainerTable';
import AddContainerModal from '../../Utills/Modal/AddContainerModal';

const AddContainer = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isModal, setIsModal] = useState(false);
    const [areacontainers, setAreaContainer] = useState([]);
    const [users, setUsers] = useState([]);
    const [role, setRole] = useState('');
    const [search, setSeach] = useState('');

    useEffect(() => {
        window.localStorage.setItem('side-id', 10);
        if (isLoading) {
            axios.get('https://api.turbodelivery.mn/areacontainer?sort=-createdAt')
                .then((e) => {
                    setAreaContainer(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e));

            axios.get('https://api.turbodelivery.mn/user?role=operator')
                .then((e) => {
                    setUsers(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e));
        }
    }, [isLoading])

    const closeModal = () => {
        setIsModal(false);
    }

    return (
        <>
            {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
            {isModal ? <AddContainerModal isUser={true} closeModal={closeModal} /> : <></>}
            <Navbar title={'Агуулах хянах самбар'} />
            <div className={styles.user_container}>
                <div className="line-three">
                    <button onClick={(e) => setIsModal(true)} className='button'>Агуулах нэмэх</button>
                    <label>
                        Search:
                        <input className='search' value={search} onChange={(e) => setSeach(e.target.value)} type="text" name="" id="" />
                    </label>
                </div>
                <AreaContainerTable users={users} areacontainers={areacontainers} roleFilter={role} search={search} />
            </div>
        </>
    )
}

export default AddContainer
