import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { CiEdit } from "react-icons/ci";
import DriverOrderStatusChangeModal from '../EditModal/DriverOrderStatusChangeModal';
import { Switch } from '@mui/material';
import ExportExcel from '../../../excelexport';
import OrderItemDetialModal from './OrderItemDetialModal';
import { IoIosAddCircleOutline } from "react-icons/io";
import { LiaTrashRestoreSolid } from "react-icons/lia";

const DriverOrderDetialModal = ({ id, closeModal, users }) => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editModal, setEditModal] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [orderStatus, setOrderStatus] = useState('');
    const [isFuture, setFuture] = useState(true);
    const [orderModal, setOrderModal] = useState(false);
    const [drivers, setDriver] = useState([]);
    const [items, setOrderItems] = useState([]);
    const [shops, setShops] = useState([]);
    const [excelData, setExcelData] = useState([{
        утас: '', хаяг: '', төлөв: '', 'нийт дүн': '', огноо: '', жолооч: '',
        бараанууд: {
            нэр: '',
            'нийт дүн': '',
            'тоо ширхэг': '',
        }
    }])

    useEffect(() => {
        if (isLoading) {
            // axios.get('https://api.turbodelivery.mn/user')
            //     .then((e) => {
            //         setShops(e.data.data.filter((e) => e.visible !== 'true'));
            //     })
            //     .catch((e) => console.log(e));

            axios.get('https://api.turbodelivery.mn/user?sort=-createdAt&role=driver')
                .then((e) => {
                    setDriver(e.data.data);
                })
                .catch((e) => console.log(e));
            // alert(id);
            axios.get('https://api.turbodelivery.mn/order?driver=' + id)
                .then((e) => {
                    setOrders(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e));
        }

        const exceldata =
            orders.filter((e) => {
                // const currentDate = dayjs();
                return isFuture ?
                    (e.isTommorow === false &&
                        dayjs(dayjs(dayjs(e.orderDate)).format('YYYY-MM-DD')).isSame(dayjs().add(8, 'hour'), 'date')) ||
                    (e.isTommorow === true && dayjs(dayjs(e.orderDate)).isSame(
                        dayjs().add(8, 'hour'), 'dates'
                    )) || (e.isTommorow === true && dayjs(dayjs(e.orderDate)).isBefore(
                        dayjs().add(8, 'hour'), 'dates'
                    )) || (e.status === 'Маргааш авах' && e.driverEdit === true
                        && dayjs(dayjs(e.orderDate)).isSame(dayjs().add(8, 'hour'), 'date'))
                    : (dayjs(e.orderDate).isAfter(dayjs().add(8, 'hour'), 'date')
                        && e.isTommorow === true) ||
                    (e.status === 'Маргааш авах' && e.driverEdit === true
                        && dayjs(e.orderDate).isAfter(dayjs().add(8, 'hour'), 'date'))
            }).map((el) => ({
                утас: el.phone,
                хаяг: el.address,
                төлөв: el.status,
                'нийт дүн': new Intl.NumberFormat('en-Us').format(el.price),
                огноо: dayjs(el.createdAt).format('YYYY-MM-DD'),
                жолооч: drivers.find(() => id === el.driver)?.name,
                бараанууд: el.order.map((e) => ({
                    нэр: e.name,
                    'нийт дүн': e.price,
                    'тоо ширхэг': e.unit,
                }))
            }));
        setExcelData(exceldata);
    }, [isLoading, orders])

    const closeEditModal = () => {
        setEditModal(false);
        setOrderModal(false);
    }

    const changeBackOrder = ({ id }) => {
        // alert(status);
        axios.put('https://api.turbodelivery.mn/order/proto/driver/' + id, {
            status: 'Хүлээгдэж байна',
            driver: 'back',
        })
            .then(() => window.location.reload(false))
            .catch((e) => alert(e));
    }

    return (
        <>
            {orders && orderModal ? <OrderItemDetialModal closeModal={closeEditModal} orders={items} /> : null}
            {editModal && orderId !== '' ? <DriverOrderStatusChangeModal
                orderStatus={orderStatus} phone={phone} address={address} id={orderId} closeModal={closeEditModal} /> :
                isLoading ? <div style={{ position: 'absolute', width: '100vw', left: 0, top: 0, height: '100vh', zIndex: 110 }} className="blur_container"><div className="loader"></div></div> :
                    <div className='blur_container' style={{ position: 'absolute', width: '100vw', left: 0, top: 0, height: '150vh', zIndex: 110 }}>
                        <div className='modal' style={{ width: '60vw' }}>
                            <div className="two-line" style={{ width: '60vw' }}>
                                <h2>Жолоочийн хүргэлтүүд</h2>
                                <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                            </div>
                            <div className="line-start">
                                <Switch value={isFuture} onChange={() => setFuture(!isFuture)} defaultChecked />
                                <p>{isFuture ? 'Өнөөдрийн захиалгууд' : 'Маргаашын захиалга'} -
                                    /{orders.filter((e) => {
                                        // const currentDate = dayjs();
                                        return isFuture ?
                                            (e.isTommorow === false &&
                                                dayjs(e.orderDate).subtract(8, 'hour')
                                                    .isSame(dayjs(), 'date')
                                            ) ||
                                            (e.isTommorow === true && dayjs(e.orderDate).subtract(8, 'hour')
                                                .isSame(
                                                    dayjs(), 'date'))
                                            || (e.status === 'Маргааш авах' && e.driverEdit === true
                                                && dayjs(e.orderDate).subtract(8, 'hour')
                                                    .isSame(dayjs(), 'date'))
                                            : (dayjs(e.orderDate).subtract(8, 'hour')
                                                .isAfter(dayjs(), 'date')
                                                && e.isTommorow === true) ||
                                            (e.status === 'Маргааш авах' && e.driverEdit === true
                                                && dayjs(e.orderDate).subtract(8, 'hour')
                                                    .isAfter(dayjs(), 'date'))
                                    }).length
                                    } ш/
                                </p>
                                <ExportExcel fileName={'name'} excelData={orders
                                    .filter((e) => (
                                        isFuture ?
                                            (e.isTommorow === false &&
                                                dayjs(e.orderDate).subtract(8, 'hour')
                                                    .isSame(dayjs(), 'date')
                                            ) ||
                                            (e.isTommorow === true && dayjs(e.orderDate).subtract(8, 'hour')
                                                .isSame(
                                                    dayjs(), 'date'))
                                            || (e.status === 'Маргааш авах' && e.driverEdit === true
                                                && dayjs(e.orderDate).subtract(8, 'hour')
                                                    .isSame(dayjs(), 'date'))
                                            : (dayjs(e.orderDate).subtract(8, 'hour')
                                                .isAfter(dayjs(), 'date')
                                                && e.isTommorow === true) ||
                                            (e.status === 'Маргааш авах' && e.driverEdit === true
                                                && dayjs(e.orderDate).subtract(8, 'hour')
                                                    .isAfter(dayjs(), 'date'))
                                    )
                                    )}
                                    haveOrders={true} drivers={drivers} shops={users} />
                            </div>
                            <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
                                <Table sx={{ minWidth: '40vw', maxWidth: '60vw', width: '60vw' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                                            <TableCell align="center" style={{ color: '#fff', fontSize: 16 }}></TableCell>
                                            <TableCell align="center" style={{ color: '#fff', fontSize: 16 }}>№</TableCell>
                                            <TableCell align="center" style={{ color: '#fff', fontSize: 16 }}>Хаяг / Утас</TableCell>
                                            <TableCell align="center" style={{ color: '#fff', fontSize: 16 }}>Төлөв</TableCell>
                                            <TableCell align="center" style={{ color: '#fff', fontSize: 16 }}>Огноо</TableCell>
                                            {/* <TableCell align="center" style={{ color: '#fff', fontSize: 16 }}>Дэлгүүр</TableCell> */}
                                            <TableCell align="center" style={{ color: '#fff', fontSize: 16 }}>Нийт дүн</TableCell>
                                            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders
                                            .filter((e) => {
                                                // const currentDate = dayjs();
                                                // const currentDate = dayjs();
                                                return isFuture ?
                                                    (e.isTommorow === false &&
                                                        dayjs(e.orderDate).subtract(8, 'hour')
                                                            .isSame(dayjs(), 'date')
                                                    ) ||
                                                    (e.isTommorow === true && dayjs(e.orderDate).subtract(8, 'hour')
                                                        .isSame(
                                                            dayjs(), 'date'))
                                                    || (e.status === 'Маргааш авах' && e.driverEdit === true
                                                        && dayjs(e.orderDate).subtract(8, 'hour')
                                                            .isSame(dayjs(), 'date'))
                                                    : (dayjs(e.orderDate).subtract(8, 'hour')
                                                        .isAfter(dayjs(), 'date')
                                                        && e.isTommorow === true) ||
                                                    (e.status === 'Маргааш авах' && e.driverEdit === true
                                                        && dayjs(e.orderDate).subtract(8, 'hour')
                                                            .isAfter(dayjs(), 'date'))
                                            })
                                            .map((row, index) => (
                                                <TableRow
                                                    className='hover'
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    key={index}
                                                >
                                                    <TableCell align="center" onClick={() => {
                                                        setOrderItems(row.order);
                                                        setOrderModal(true)
                                                    }}>
                                                        <IoIosAddCircleOutline size={25} color='#838383' cursor={'pointer'} />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.address} / {row.phone}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.status}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {dayjs(row.orderDate)
                                                            .subtract(8, 'hour')
                                                            .format('YYYY-MM-DD')
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.price}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {/* uildel */}
                                                        {/* <input style={{visibility: 'hidden'}} type="number" name='staticUnit' id="" /> */}
                                                        <CiEdit onClick={() => {
                                                            setEditModal(true);
                                                            setOrderId(row._id);
                                                            setAddress(row.address);
                                                            setPhone(row.phone);
                                                            setOrderStatus(row.status);
                                                        }} size={30} color='blue' />
                                                        <LiaTrashRestoreSolid
                                                            onClick={() => changeBackOrder({ id: row._id })}
                                                            size={30} color='red' />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
            }
        </>
    )
}

export default DriverOrderDetialModal