import React, { useEffect, useState } from 'react';
import styles from './Login.module.css';
import img from '../../Assets/Login.jpg';
import icon from '../../Assets/icon-2.png';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isSnackBar, setIsSnackBar] = useState(false);
  const [roleError, setRoleError] = useState(false);

  const userCache = JSON.parse(window.localStorage.getItem('userId'));

  useEffect(() => {
    if (userCache && userCache.name !== null) {
      navigate('/dashboard');
    }
  }, [userCache]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const login = () => {
    axios
      .post('https://api.turbodelivery.mn/user/login', {
        email: email,
        password: password,
      })
      .then((e) => {
        if (e.data.user.role === 'admin') {
          window.localStorage.setItem('userId', JSON.stringify(e.data.user));
          // window.location.reload(false);
          navigate('/dashboard');
        } else {
          setRoleError(true);
        }
      })
      .catch(() => setIsSnackBar(true));
  };

  const handleClose = () => {
    setIsSnackBar(false);
    setRoleError(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.img_item}>
        <img src={img} alt="" />
      </div>
      <div className={styles.login}>
        <img src={icon} alt="" />
        <div>
          <Snackbar
            className={styles.snackbar}
            open={isSnackBar}
            autoHideDuration={10000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              iconMapping={{ vertical: 'top', horizontal: 'right' }}
              className={styles.alert}
            >
              Имэйл эсвэл нууц үг буруу байна.
            </Alert>
          </Snackbar>
          <Snackbar
            className={styles.snackbar}
            open={roleError}
            autoHideDuration={10000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              iconMapping={{ vertical: 'top', horizontal: 'right' }}
              className={styles.alert}
            >
              Зөвхөн админ эрхээр нэвтрэх боломжтой.
            </Alert>
          </Snackbar>
          <label style={{ alignItems: 'center' }}>
            <p>Нэвтрэх нэр</p>
            <input
              type="email"
              placeholder="Нэвтрэх нэр"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label style={{ alignItems: 'center' }}>
            <p>Нууц үг</p>
            <input
              type="password"
              placeholder="Нууц үг"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </label>
        </div>
        <button onClick={login} style={{ width: window.screen.width > 768 ? '14vw' : '50vw' }} className="button">
          Нэвтрэх
        </button>
      </div>
    </div>
  );
};

export default Login;
