import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login/Login';
import Sidebar from './Components/Sidebar/Sidebar';
import Dashboard from './Pages/Dashboard/Dashboard';
import Order from './Pages/Order/Order';
import Finance from './Pages/Finance/Finance';
import Container from './Pages/Container/Container';
import Items from './Pages/Directry/Items/Items';
import Category from './Pages/Directry/Category/Category';
import Shops from './Pages/Shops/Shops';
import Accounting from './Role/Accounting/Accounting';
import Driver from './Role/Driver/Driver';
import ContainerReport from './Pages/Report/ContainerReport/ContainerReport';
import ShopReport from './Pages/Report/ShopReport/ShopReport';
import Users from './Pages/Users/Users';
import Driver_Orders from './Pages/Driver/Driver_Orders/Driver_Orders';
import DriverHistory from './Pages/Driver/Driver_History/Driver_History';
import OrderFiltered from './Pages/Order/OrderFiltered';
import OrderItems from './Pages/Order/OrderItems';
import ContainerOfPackage from './Pages/Container/ContainerOfPackage';
import AddContainer from './Pages/AddContainer/AddContainer';
import { useEffect, useState } from 'react';
import axios from 'axios';
import State from './Pages/State/State';
import Message from './Pages/Message/Message';

function App() {
  const [orders, setOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = window.localStorage.getItem('userId');
  const [users, setUsers] = useState([]);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    if (isLoading) {
      //&isSaved=false
      // Make sure `orders` is correctly initialized (e.g., useState([]))
      Promise.all([
        axios.get('https://api.turbodelivery.mn/order/not-cache/get?startdate=2025-01-13'),
        axios.get('https://api.turbodelivery.mn/order/cache/get?enddate=2025-01-13')
      ])
        .then(([notCache, cache]) => {
          // Check if responses have data before attempting to access it
          const notCacheOrders = notCache?.data?.data || [];
          const cacheOrders = cache?.data?.data || [];

          // Combine both data arrays
          const combinedOrders = [...notCacheOrders, ...cacheOrders];

          // Sort orders by createdAt (latest first)
          const sortedOrders = combinedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

          // Set the sorted orders
          setOrder(sortedOrders);

          // Mark loading as complete
          setIsLoading(false);
        })
        .catch((e) => {
          console.error('Error fetching orders:', e);
          setIsLoading(false); // Ensure loading state is cleared even in case of an error
        });


      // axios.get('https://api.turbodelivery.mn/user?role=user')
      //   .then((e) => {
      //     setUsers(e.data.data);
      //   })
      //   .catch((e) => alert(e));

      // axios.get('https://api.turbodelivery.mn/shop')
      //   .then((e) => {
      //     setShops(e.data.data.filter((e) => e.visible !== 'true'));
      //   })
    }
  }, [isLoading])
  return (
    <>
      <Router>
        {user !== null ? <Sidebar /> : ''}
        {isLoading ? <div className="loader-container"><div className='loader'></div></div> :
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/dashboard' element={
              <>
                <Dashboard ordersCatch={orders} />
              </>
            } />
            <Route path='/order' element={<Order orderscatch={orders} />} />
            <Route path='/orderdetial/:id/:date' element={<OrderFiltered />} />
            <Route path='/finance' element={<Finance ordersCatch={orders} />} />
            <Route path='/container' element={<Container />} />
            <Route path='/containerpackage' element={<ContainerOfPackage />} />
            <Route path='/items' element={<Items orders={orders} />} />
            <Route path='/category' element={<Category />} />
            <Route path='/shop' element={<Shops />} />
            <Route path='/accounting' element={<Accounting />} />
            <Route path='/driver' element={<Driver />} />
            <Route path='/reportcontainer' element={<ContainerReport shopCatch={shops} usersCatch={users} ordersCatch={orders} />} />
            <Route path='/reportshop' element={<ShopReport shopCatch={shops} userCatch={users} ordersCatch={orders} />} />
            <Route path='/driverorder' element={<Driver_Orders ordersCatch={orders} />} />
            <Route path='/driverhistory' element={<DriverHistory ordersCatch={orders} />} />
            <Route path='/users' element={<Users />} />
            <Route path='/orderitems' element={<OrderItems ordersCatch={orders} />} />
            <Route path='/addcontainer' element={<AddContainer />} />
            <Route path='/state' element={<State />} />
            <Route path='/message' element={<Message />} />
          </Routes>}
      </Router>
    </>
  );
}

export default App;
