import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import axios from 'axios';
import { MdDeleteForever } from "react-icons/md";
import DeleteModal from '../Modal/Uttils/DeleteModal';

export default function ItemsTable({ posts, search, users, user, orders }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [id, setId] = React.useState('');
  const [askModal, setAskModal] = React.useState(false);
  const [successDelete, setSuccessDelete] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Энэ мөрөнд setPage функцыг дуудаж 0-р тогтоож өгнө
  };

  const deleteItem = ({ _id }) => {
    setLoading(true);
    // alert(_id);
    if (_id) {
      axios.delete('https://api.turbodelivery.mn/post/' + _id)
        .then((e) => {
          window.location.reload(false);
          setLoading(false);
        }).catch((e) => alert(e));
    }
  }

  const closeModal = () => {
    setAskModal(false);
  }

  const canDelete = (postId) => {
    for (const order of orders) {
      if (order.order.some(item => item.name === postId)) {
        return false;
      }
    }
    return true;
  };

  return (
    <>{
      askModal ? <DeleteModal text={'Барааг устгах'} closeModal={closeModal} click={() => deleteItem({ _id: id })} /> :
        loading ? <div className="loader-container"><div className='loader'></div></div> :
          <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
            <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                  <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Харилцагч</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Зураг</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="left">Үнэ</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="left">Барааны нэр</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Дэлгүүр</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Барааны код</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Барааны бүлэг</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Бүртгэсэн огноо</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posts
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter((e) => {
                    return e.title && search !== '' ? e.title.toLowerCase().includes(search) : e;
                  })
                  .filter((e) => user ? e.user === user : e)
                  .map((row, index) => (
                    <TableRow className='hover'
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{users
                        .filter((e) => e._id === row.user)
                        .map((e) => (
                          <p>{e.name}</p>
                        ))}
                      </TableCell>
                      <TableCell align="center"><img
                        src={'https://api.turbodelivery.mn/' + row.cover} alt=""
                      /></TableCell>
                      <TableCell component="th" scope="row">
                        {row.price}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.title}
                      </TableCell>
                      <TableCell align="center">{row.shop}</TableCell>
                      <TableCell align="center">{row._id}</TableCell>
                      <TableCell align="center">{row.category}</TableCell>
                      <TableCell align="center">{dayjs(row.createdAt).format('YYYY-MM-DD')}</TableCell>
                      <TableCell align="center">
                        {canDelete(row.title) &&
                          <MdDeleteForever color='red' size={25} cursor={'pointer'} onClick={() => {
                            setId(row._id);
                            setAskModal(true);
                          }} />
                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={posts
                .filter((e) => {
                  return e.title && search !== '' ? e.title.toLowerCase().includes(search) : e;
                }).filter((e) => user ? e.user === user : e).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
    }</>
  );
}