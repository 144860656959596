import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { AiOutlineUserDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import TablePagination from '@mui/material/TablePagination';
import EditUserModal from '../Modal/EditModal/EditUserModal';
import axios from 'axios';
import DeleteModal from '../Modal/Uttils/DeleteModal';

export default function UsersTable({ users, roleFilter, search, isDriver }) {
  const [isModal, setIsModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [deleteId, setDeleteUser] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);

  const closeModal = () => {
    setIsModal(false);
    setDeleteModal(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteUser = () => {
    axios.delete('https://api.turbodelivery.mn/user/' + deleteId)
      .then(() => window.location.reload(false))
      .catch(() => console.log('err'));
  }

  return (
    <>

      {isModal ? <EditUserModal user={selectedUser} closeModal={closeModal} /> : null}
      {deleteModal ? <DeleteModal text={'Хэрэглэгч устгах гэж байна.'} click={deleteUser} closeModal={closeModal} /> : null}
      <TableContainer style={{ width: window.screen.width < 769 ? '95vw' : null }} component={Paper}>
        <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: '#2c9961', color: '#fff' }}>
              <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Role</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Дэлгүүрийн нэр</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Утасны дугаар</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">И-мэйл</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Бүртгэсэн огноо</TableCell>
              {isDriver ? <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Харилцагч нуух</TableCell> : null}
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .filter((e) => roleFilter ? e.role.includes(roleFilter) : e)
              .filter((e) => search ? e.name.toLowerCase().includes(search.toLowerCase()) : e).map((row, index) => (
                <>
                  <TableRow className='hover'
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="center">
                      {row.role}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">{row.shop}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{dayjs(row.createdAt).format('YYYY-MM-DD')}</TableCell>
                    {isDriver ? <TableCell align="center">
                      <div className={row.visible ? 'red-border' : 'green-border'}>
                        {row.visible ? 'Нуусан' : 'Нуугаагүй'}
                      </div>
                    </TableCell> : null}
                    <TableCell align="center">
                      <CiEdit
                        size={30} style={{ paddingRight: '30px', cursor: 'pointer' }} color='blue'
                        onClick={() => {
                          setIsModal(true);
                          setSelectedUser(row); // Хэрэв хэрэглэгч дээр дарсан тохиолдолд тухайн хэрэглэгчийн мэдээллийг хадгалах
                        }}
                      />
                      <AiOutlineUserDelete
                        onClick={() => {
                          setDeleteModal(true);
                          setDeleteUser(row._id)
                        }}
                        style={{ cursor: 'pointer' }} size={30} color='red' />
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}